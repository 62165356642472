import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "input", "report" ]

  remaining = 0
  limit = 30

  connect() {
    this.calculate()
  }

  calculate() {
    let text = this.inputTarget.value

    if (text.length > this.limit) {
      text = this.inputTarget.value = text.substring(0, this.limit)
    }

    text = this.inputTarget.value = text.match(/[\w ]*/).toString()

    this.remaining = this.limit - text.length

    this.reportTarget.innerHTML = this.remaining
  }
}
