import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    sessionKey: String,
    sessionKeyExpiresAt: String,
    count: { type: Number, default: 0 }
  }

  static targets = [
    'form',
    'cardholderName',
    'cardNumber',
    'expiryDate',
    'securityCode',
    'merchantSessionKey',
    'cardIdentifier',
    'browserLanguage',
    'browserColorDepth',
    'browserScreenHeight',
    'browserScreenWidth',
    'browserTz',
    'browserUserAgent'
  ]

  connect() {
    this.countValue = 0
    this.browserLanguageTarget.value = navigator.language
    this.browserColorDepthTarget.value = screen.colorDepth
    this.browserScreenHeightTarget.value = screen.height
    this.browserScreenWidthTarget.value = screen.width
    this.browserTzTarget.value = new Date().getTimezoneOffset()
    this.browserUserAgentTarget.value = navigator.userAgent
  }

  send(event) {
    event.preventDefault()

    this.expiryDateTarget.value = this.expiryDateTarget.value.replace('/', '')

    let myself = this

    sagepayOwnForm({ merchantSessionKey: myself.sessionKeyValue })
      .tokeniseCardDetails({
        cardDetails: {
          cardholderName: myself.cardholderNameTarget.value,
          cardNumber:     myself.cardNumberTarget.value,
          expiryDate:     myself.expiryDateTarget.value,
          securityCode:   myself.securityCodeTarget.value
        },
        onTokenised: function(result) {
          if (result.success) {
            myself.merchantSessionKeyTarget.value = myself.sessionKeyValue
            myself.cardIdentifierTarget.value = result.cardIdentifier
            myself.formTarget.submit()
          } else {
            myself.countValue = myself.countValue + 1

            if (myself.countValue > 2) {
              window.location.reload(true)
            } else {
              document.querySelector('.js-errors').innerHTML = myself.render_errors(result.errors)
            }
          }
        }
      })
  }

  render_errors(errors) {
    let error_list = errors.map(e => `<li class="">${e.message}</li>`).join('')

    return `
      <div class="p-4 my-6 bg-red-100 border border-red-300 rounded">
        <ul class="pl-4 list-disc">
          ${error_list}
        </ul>
      </div>
    `
  }
}
