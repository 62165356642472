import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "panel" ]
  static values = { overflowHidden: Boolean, default: true }

  open(e) {
    e.preventDefault()

    if (this.overflowHiddenValue == true) {
      document.querySelector("body").classList.add("overflow-hidden")
    }

    this.panelTarget.classList.remove("hidden")
  }

  close(e) {
    e.preventDefault()

    this.panelTarget.classList.add("hidden")

    if (this.overflowHiddenValue == true) {
      document.querySelector("body").classList.remove("overflow-hidden")
    }
  }
}
