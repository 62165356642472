import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = [ "disabled" ]

  static targets = [ "input", "method" ]

  static values = {
    available: Boolean
  }

  connect() {
    this.update()
  }

  update() {
    let el = this.checked()

    if (el.dataset.safeplaceAvailableValue == 'true') {
      this.inputTarget.classList.remove(this.disabledClass)
    } else {
      this.inputTarget.classList.add(this.disabledClass)
    }
  }

  clear(e) {
    let el = this.checked()

    if (el.dataset.safeplaceAvailableValue != 'true') {
      this.inputTarget.value = ''
    }
  }

  checked() {
    return this.methodTargets.filter(el => el.checked)[0]
  }
}
